@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /*
  Height of the global nav bar and contextual header toolbars that are displayed
  in place of the global nav bar, such as the image selection toolbar and
  lightbox toolbar.
  */
  --headerToolbarHeight: 60px;

  /*
  Standard outline style for focused elements
  */
  --defaultFocusWidth: 2px;

  /*
  Standard focus outline for focused elements
  */
  --defaultFocusOutline: solid var(--defaultFocusWidth) var(--color-info-600);
  --defaultFocusOutlineOffset: -2px;

  /*
  When implementing styles for a given Reach UI component, the library will
  normally warn if a component's matching CSS hasn't been included. In order to
  prevent this additional check (and warning), a root CSS variable can be set to
  1 to indicate to Reach that we are taking care of all styles for each
  component below. Note that these selectors are not pure and cannot be included
  in component-level CSS Modules.

  See https://reacttraining.com/reach-ui/styling#skip-including-styles.
  */
  --reach-menu-button: 1;
  --reach-tooltip: 1;

  /*
  UI Colors
  */
  --color-background-tile: var(--color-gray-ash);
  --color-image-mask: rgb(34 93 167 / 60%); /* Placed over an image to indicate a masked off area for certain commerce items such as canvas wraps */
  --color-page-background: var(--color-gray-carbon);
}

html {
  overflow-y: var(--bodyOverflowY, scroll);
  font-size: 16px; /* Sets the base font size (1rem) that all font sizes are based on */
  background-color: var(--color-page-background);
}

html:not(.loaded) * {
  /*
  This prevents transitions from being triggered on page load (when
  DOMContentLoaded fires) in Chrome and Safari as a result of CSS resets that
  are applied in other CSS files.

  There's a corresponding bit of JS in `<MercuryApp>` that adds a `loaded` class
  to the document element once the page is hydrated, allowing transitions to run
  after that point.

  Discussion: https://smugmug.slack.com/archives/C02BRTY39/p1624906860391000
  */
  transition: none !important;
}

body {
  @apply text-base;
  padding: env(safe-area-inset-top) env(safe-area-inset-right) 0 env(safe-area-inset-left);
  color: var(--color-text-light-medium);
  font-family: theme("fontFamily.sans");
}

body[data-webkit-user-select-none] {
  -webkit-user-select: none;
}

[data-webkit-touch-callout-none] {
  -webkit-touch-callout: none;
}

/*
Reach UI menu popover. Setting a z-index of 1 fixes visual conflicts with Tile
selection checkboxes.
*/
[data-reach-menu-popover] {
  z-index: 1;
}
