/* `.toaster` CSS scope is necessary to override `react-hot-toast`'s styles. */
.toaster .toast {
  @apply text-base;
  max-width: 460px;
  padding: theme("spacing.4") theme("spacing.6");
  color: var(--color-text-light);
  background: var(--color-gray-graphite);
  border-radius: theme("spacing.1");
  box-shadow: 0 theme("spacing.4") theme("spacing.8") theme("spacing.1") var(--color-black-70);
}

.toaster .toast [role="status"] {
  margin: 0;
}

.toaster [data-toast-type="success"] .toast [role="status"],
.toaster [data-toast-type="error"] .toast [role="status"],
.toaster [data-toast-type="loading"] .toast [role="status"] {
  margin-left: theme("spacing.2");
}

.toaster .toast [role="separator"] {
  height: 100%;
  margin-left: theme("spacing.4");
  padding-left: theme("spacing.4");
  border-left: 1px solid var(--color-white-16);
}

/*
Typically adjacent buttons appear in toasts when a close toast button directly
follows a call to action button, as shown in the design.
*/
.toaster .toast button + button {
  margin-left: theme("spacing.2");
}

@keyframes toastEnter {
  0% {
    transform: translate3d(0, 200%, 0);
    opacity: 0;
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes toastExit {
  0% {
    transform: translate3d(0, 0, -1px);
    opacity: 1;
  }

  100% {
    transform: translate3d(0, 200%, 0);
    opacity: 0;
  }
}

@media (prefers-reduced-motion) {
  @keyframes toastEnter {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes toastExit {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
}
