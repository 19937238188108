/*
The `safe-area-inset-right` is to protect against going under the notch in
landscape orientation.
*/
.cookieBanner {
  position: fixed;
  right: env(safe-area-inset-right);
  bottom: 0;
  z-index: 2; /* Can't rely entirely on DOM order since other modals/portals may have opened */
  box-sizing: border-box;
  width: 100%;
  max-width: 528px;
  padding: theme("spacing.8");
  color: var(--color-text-light);
  background-color: var(--color-black);
}

.cookieBannerTitle {
  @apply text-xl;
  font-weight: 300;
}

.cookieBannerButtonGroup {
  display: flex;
  justify-content: flex-end;
}

@screen small {
  .cookieBanner {
    margin-right: theme("spacing.8");
    margin-bottom: theme("spacing.8");
  }
}
